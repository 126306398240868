import React from 'react'
import BoxStaticPageList from '../../../src/common/components/BoxStaticPageList/BoxStaticPageList'
import LayoutLanding from '../../../src/common/components/layouts/layoutLanding/LayoutLanding'
import { getFullCompany } from '../../../src/common/utils/getCompany'

const ImageBateria = `https://${process.env.RODI_AWS_BUCKET}/landings/moto/bateria-moto-elpaso2000.jpg`
const ImageAceite = `https://${process.env.RODI_AWS_BUCKET}/landings/moto/paquete-mantenimiento-moto-elpaso2000.jpg`

const ImageDisco = `https://${process.env.RODI_AWS_BUCKET}/landings/moto/frenos-de-moto-elpaso2000.jpg`

const ImageKit = `https://${process.env.RODI_AWS_BUCKET}/landings/moto/kit-de-transmision-moto-elpaso2000.jpg`

const ImageFiltroAceite = `https://${process.env.RODI_AWS_BUCKET}/landings/moto/filtro-aceite-moto-elpaso2000.jpg`
const ImageFiltroAire = `https://${process.env.RODI_AWS_BUCKET}/landings/moto/filtro-aire-moto-elpaso2000.jpg`
const ImageEmbrague = `https://${process.env.RODI_AWS_BUCKET}/landings/moto/embrague-moto-elpaso2000.jpg`
const ImageRueda = `https://${process.env.RODI_AWS_BUCKET}/landings/moto/rueda-neumatico-moto-elpaso2000.jpg`

interface Props {
  styles: { [k: string]: string }
}

const sectionsEs = [
  {
    title: 'Servicios para Moto de El Paso 2000',
    items: [
      {
        title: 'Cambio de Aceite',
        description: `Ofrecemos el mejor precio para mantener tu moto siempre a punto con su cambio de aceite correspondiente. Solicita cita previa en uno de nuestros talleres y déjate asesorar por nuestros especialistas.   `,
        img: ImageAceite,
        route: 'moto.cambio_aceite',
      },
      {
        title: 'Batería de Moto',
        description: ` Un elemento indispensable para el arranque del motor de cualquier motocicleta. En El Paso 2000 te asesoraremos sobre el estado de la batería de tu moto y te aconsejaremos cuando es mejor realizar su sustitución.`,
        img: ImageBateria,
        route: 'moto.baterias',
      },
      {
        title: 'Filtros de Aceite',
        description: `Es el encargado de contener las partículas que se desprenden del motor de la moto debido al desgaste. Su misión es que el motor de tu moto trabaje con un aceite libre de impurezas.`,
        img: ImageFiltroAceite,
        route: 'moto.filtros_aceite',
      },
      {
        title: 'Filtros de Aire',
        description: `¿Te hacen ruido los frenos? ¿Notas vibraciones? En El Paso 2000 nos encargamos del mantenimiento, el cambio de pastillas y discos de freno de tu moto. Disponemos de stock para toda clase de modelos. `,
        img: ImageFiltroAire,
        route: 'moto.filtros_aire',
      },
      {
        title: 'Frenos de Moto',
        description: `¿Te hacen ruido los frenos? ¿Notas vibraciones? En El Paso 2000 nos encargamos del mantenimiento, el cambio de pastillas y discos de freno de tu moto. Disponemos de stock para toda clase de modelos. `,
        img: ImageDisco,
        route: 'moto.frenos',
      },
      {
        title: 'Kit de Transmisión',
        description: `También llamado kit de arrastre. Se encarga de trasmitir la potencia del motor a la rueda trasera. Si notas que la cadena tiende a destensarse rápidamente quizás necesita un cambio.`,
        img: ImageKit,
        route: 'moto.kit',
      },
      {
        title: 'Embrague de Moto',
        description: `Es la pieza que coordina las marchas de la moto con el giro del motor. Un embrague deteriorado puede provocar comportamientos extraños al cambiar de marcha.`,
        img: ImageEmbrague,
        route: 'moto.embrague',
      },
      {
        title: 'Neumáticos de Moto',
        description: `Son los responsables de mantenerte agarrado al asfalto en cada curva. 
        Los neumáticos son una pieza clave de seguridad para tu moto.`,
        img: ImageRueda,
        route: 'neumaticos.todo_sobre',
      },
    ],
  },
]

const MotoEs = ({ styles }: Props) => (
  <LayoutLanding
    title="Servicios para tu moto"
    textRecomendation="Un buen motorista debe ofrecer el mejor de los cuidados a su moto. Cuando te mueves con ella la seguridad al manillar es una prioridad. En los talleres El Paso 2000 te ofrecen neumáticos al mejor precio y te asesoran para mantener tu moto siempre perfecta."
    textButtonCTA="ENCUENTRA TU TALLER"
    linkButtonCTA="/nuestros-talleres/"
    descriptionCTA="¿Necesitas que te ayudemos con el mantenimiento de tu moto? Todo lo que necesitas lo encontrarás en El Paso 2000. ">
    <React.Fragment>
      <p>
        En <strong>{getFullCompany()} </strong>nos ocupamos del mantenimiento
        completo de tu motocicleta. Ofrecemos soluciones{' '}
        <strong>al mejor precio. </strong> Nos encargamos de la puesta a punto
        de todos los elementos que componen la motocicleta: sistema de frenado,
        kit de transmisión, batería, revisiones, cambio de filtros…
      </p>
      <p>
        Te explicamos todos los servicios para moto que encontrarás en los
        talleres {getFullCompany()} .
      </p>
      {sectionsEs.map((section) => (
        <BoxStaticPageList
          key={section.title}
          title={section.title}
          items={section.items}
        />
      ))}
    </React.Fragment>
  </LayoutLanding>
)

const MotoContents = ({ ...props }: Props) => <MotoEs {...props} />

export default MotoContents
